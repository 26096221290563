import React from "react";
import  Link  from "next/link";
import { parseDomain, ParseResultType } from 'parse-domain';

const TaxidFooter = () => {
  let domainName = "Taxid-US";
  let topLevelDomain = "com"
  const parseResult = parseDomain(typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '');
  if (parseResult.type === ParseResultType.Listed) {
    const { domain, topLevelDomains } = parseResult;
    domainName = domain;
    topLevelDomain = topLevelDomains;
  }
  return (
    <div className="footer-wrapper">
      <div className="footer">             
        <div className="links">
          <ul>
            {/* <li>
              <Link href="/incorps/home">Incorporate LLC</Link>
            </li> */}
            <li>
              <Link href="/">{"Apply For " +domainName}</Link>
            </li>
            <li>
              <Link href="/terms">Terms and Conditions</Link>
            </li> 
            <li>
              <Link href="/pricing">Services & Pricing</Link>
            </li>
            <li>
              <Link href="/privacy">Privacy Policy  </Link>
            </li>
            <li>
              <Link href="/about">FAQ</Link>
            </li>
            <li>
              <Link href="/contactus">Contact Us</Link>
            </li>          
            <li>
              <Link href="/status">Order Status</Link>
            </li>                                  
          </ul>
        </div>        
        <div className="text">          
          <p>
            {domainName}.{topLevelDomain} acts as an Authorized e-File Provider as described in the instructions to Form SS-4 to help clients obtain Federal Tax ID Numbers from the Internal Revenue Service (the “IRS”) in a timely manner. It does not provide legal, financial, or professional advice. This website is not affiliated with the Internal Revenue Service or any governmental organization. You are able to obtain an EIN number yourself by working directly with the IRS, but they will not be able to assist you with the process or provide e-mail support.
          </p>
        </div>
        <div className="copyright">
          <p>Copyright© 2025 {domainName}. All Rights Reserved </p>
        </div>
      </div>
    </div>
  );
};

export default TaxidFooter;
